exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-note-js": () => import("./../../../src/pages/note.js" /* webpackChunkName: "component---src-pages-note-js" */),
  "component---src-pages-past-predict-js": () => import("./../../../src/pages/past_predict.js" /* webpackChunkName: "component---src-pages-past-predict-js" */),
  "component---src-pages-today-predict-forcus-js": () => import("./../../../src/pages/today_predict_forcus.js" /* webpackChunkName: "component---src-pages-today-predict-forcus-js" */),
  "component---src-pages-today-predict-js": () => import("./../../../src/pages/today_predict.js" /* webpackChunkName: "component---src-pages-today-predict-js" */),
  "component---src-templates-post-markdown-js": () => import("./../../../src/templates/post_markdown.js" /* webpackChunkName: "component---src-templates-post-markdown-js" */),
  "component---src-templates-post-past-race-predict-js": () => import("./../../../src/templates/post_past_race_predict.js" /* webpackChunkName: "component---src-templates-post-past-race-predict-js" */),
  "component---src-templates-post-race-predict-js": () => import("./../../../src/templates/post_race_predict.js" /* webpackChunkName: "component---src-templates-post-race-predict-js" */),
  "component---src-templates-select-note-js": () => import("./../../../src/templates/select_note.js" /* webpackChunkName: "component---src-templates-select-note-js" */),
  "component---src-templates-select-today-predict-js": () => import("./../../../src/templates/select_today_predict.js" /* webpackChunkName: "component---src-templates-select-today-predict-js" */)
}

